import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

const HIDDEN_CLASS = "-hidden"
const SHIFT_CLASS = "-shift"

export default class ReplacementCar extends Controller {
  static targets = ["replacements", "replacedBy"]
  static values = {
    csrf: String,
    deleteUrl: String,
    carPk: String,
    replacementPk: String,
    createUrl: String,
  }

  connect() {
    this.dayElement = this.element.parentNode.parentNode.parentNode.parentNode.parentNode
  }

  open(event) {
    if (event.clientX + 400 > window.innerWidth) this.replacementsTarget.classList.add(SHIFT_CLASS)

    this.replacementsTarget.classList.remove(HIDDEN_CLASS)

    event.preventDefault()
    event.stopPropagation()
  }

  blur(event) {
    if (!this.element.contains(event.relatedTarget)) this.replacementsTarget.classList.add(HIDDEN_CLASS)
  }

  remove() {
    this.replacedByTarget.value = ""
    if (!this.replacementPkValue) return

    // eslint-disable-next-line compat/compat
    fetch(this.deleteUrlValue + `?replacement_car_pk=${this.replacementPkValue}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
    }).then((response) => {
      if (!response.ok) {
        new Notyf({ duration: 2000, ripple: false }).error("Une erreur est survenue lors de la suppression du véhicule de remplacement")
      } else {
        new Notyf({ duration: 2000, ripple: false }).success("Le véhicule de remplacement a été supprimé avec succès")
        this.dayElement.dispatchEvent(new CustomEvent("reload"))
      }
    })
  }

  selectReplacementCar(event) {
    // eslint-disable-next-line compat/compat
    fetch(this.createUrlValue + `?replacement_car_pk=${event.currentTarget.dataset.replacementCarCarIdData}&date=${event.currentTarget.dataset.replacementCarDayData}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
    }).then((response) => {
      if (!response.ok) {
        new Notyf({ duration: 2000, ripple: false }).error("Une erreur est survenue lors de l'ajout du véhicule de remplacement")
      } else {
        new Notyf({ duration: 2000, ripple: false }).success("L'affectation du véhicule de remplacement a été effectuée avec succès")
        this.close(event)
      }
    })
  }

  close(event) {
    this.replacementsTarget.classList.add(HIDDEN_CLASS)
    this.dayElement.dispatchEvent(new CustomEvent("reload"))

    event.preventDefault()
    event.stopPropagation()
  }
}
