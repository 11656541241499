import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class DashboardSearch extends Controller {
  static targets = ["results"]

  display() {
    setTimeout(() => {
      this.resultsTarget.classList.remove(HIDDEN_CLASS)
    }, 150)
  }

  blur(event) {
    if (this.element === event.target || this.element.contains(event.target)) return
    this.resultsTarget.classList.add(HIDDEN_CLASS)
  }
}
