/* eslint-disable compat/compat */
import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

export default class CompanyShare extends Controller {
  static targets = ["input"]
  static values = { resetRegistrationUrl: String, csrf: String }

  resetRegistrationLink() {
    fetch(this.resetRegistrationUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
    }).then((response) => response.json())
      .then((data) => {
        this.inputTarget.value = data.registration_link
        new Notyf({ duration: 4000, ripple: false }).success("Le lien de partage a été réinitialisé avec succès")
      })
      .catch(() => {
        new Notyf({ duration: 4000, ripple: false }).error("Une erreur est survenue lors de la réinitialisation du lien de partage")
      })
  }

  copy() {
    const text = this.inputTarget.value
    const item = new ClipboardItem({
      "text/plain": new Blob([text], { type: "text/plain" }),
    })
    navigator.clipboard.write([item])
      .then(() => {
        new Notyf({ duration: 4000, ripple: false }).success("Le lien de partage a été copié dans le presse-papier")
      })
  }
}
