import { Application } from "@hotwired/stimulus"
import "htmx.org"

import ContractForm from "./components/ContractForm"
import CompanyShare from "./components/CompanyShare"
import Dashboard from "./components/dashboard/Dashboard"
import DashboardSearch from "./components/dashboard/DashboardSearch"
import DelayWorks from "./components/dashboard/DelayWorks"
import Menu from "./components/Menu"
import Note from "./components/dashboard/Note"
import DayReloader from "./components/dashboard/DayReloader"
import ReplacementCar from "./components/dashboard/ReplacementCar"
import ReportCar from "./components/dashboard/ReportCar"
import SiretVerification from "./components/SiretVerification"
import SearchAutoScroll from "./components/dashboard/SearchAutoScroll"
import StateChoice from "./components/dashboard/StateChoice"
import UsersStats from "./components/admin/UsersStats"
import WorkChart from "./components/dashboard/WorkChart"

const app = Application.start()
app.register("contract-form", ContractForm)
app.register("company-share", CompanyShare)
app.register("dashboard", Dashboard)
app.register("dashboard-search", DashboardSearch)
app.register("delay-works", DelayWorks)
app.register("menu", Menu)
app.register("note", Note)
app.register("day-reloader", DayReloader)
app.register("replacement-car", ReplacementCar)
app.register("report-car", ReportCar)
app.register("siret-verification", SiretVerification)
app.register("search-auto-scroll", SearchAutoScroll)
app.register("state-choice", StateChoice)
app.register("users-stats", UsersStats)
app.register("work-chart", WorkChart)
