import { Controller } from "@hotwired/stimulus"

export default class SearchAutoScroll extends Controller {
  static targets = ["result"]

  connect() {
    setTimeout(() => {
      this.hasResultTarget && this.resultTarget.scrollIntoView({ behavior: "smooth", block: "center" })
    }, 700)
  }
}
