import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const FOCUS_CLASS = "-focus"
const DEACTIVATED_CLASS = "-deactivated"
const EMPTY_CLASS = "-empty"
const CAREFUL_CLASS = "-careful"

export default class Dashboard extends Controller {
  static targets = ["carOptions", "car", "newCar", "day", "delayedWork"]

  connect() {
    this.dragging = false
    document.addEventListener("mousemove", this.updateFollowingDivsPosition.bind(this))
  }

  displayOptions(event) {
    const target = event.currentTarget.parentNode.parentNode
    target.nextElementSibling.classList.toggle(HIDDEN_CLASS)
    target.classList.toggle(FOCUS_CLASS)
    this.hideCarOptions(target, target.nextElementSibling)

    event.stopPropagation()
    event.preventDefault()
  }

  toggleDragging() {
    this.dragging = !this.dragging
    this.toggleCarsDeactivation()

    if (!this.dragging) {
      this.followingDoms.forEach((dom) => {
        dom.style.position = "inherit"
      })

      this.dayTargets.forEach((day) => {
        day.classList.remove(CAREFUL_CLASS)
        day.children[0].classList.remove(CAREFUL_CLASS)
      })
    }
  }

  hoverStart(event) {
    this.hover(event, "start")
  }

  hoverEnd(event) {
    this.hover(event, "end")
  }

  insert(event, deleteDoms = true) {
    if (!this.dragging) return

    const baseDayElement = this.followingDoms[0].parentNode
    if (baseDayElement.childElementCount === 2) {
      baseDayElement.classList.add(EMPTY_CLASS)
    }

    event.stopPropagation()
    event.preventDefault()
    this.dropCar(event, deleteDoms)
    this.toggleDragging()
  }

  markDueDateExceeded(dueDate) {
    if (!dueDate) return

    this.dayTargets.forEach((day) => {
      if (new Date(day.dataset.dashboardDateValue) >= new Date(dueDate)) {
        day.classList.add(CAREFUL_CLASS)
        day.children[0].classList.add(CAREFUL_CLASS)
      }
    })
  }

  // ##### Internal functions #####

  hover(event, type) {
    if (!this.dragging) return

    const dayElement = event.currentTarget
    this.expandChart(dayElement, type === "start")

    if (type === "start") {
      dayElement.classList.add(FOCUS_CLASS)
    } else {
      dayElement.classList.remove(FOCUS_CLASS)
    }
  }

  hideCarOptions(targetDom = null, optionsDom = null) {
    this.carOptionsTargets.forEach((carOptions) => {
      if (carOptions !== optionsDom) {
        carOptions.classList.add(HIDDEN_CLASS)
      }
    })

    this.carTargets.forEach((car) => {
      if (car !== targetDom) {
        car.classList.remove(FOCUS_CLASS)
      }
    })

    this.delayedWorkTargets.forEach((work) => {
      if (work !== targetDom) {
        work.classList.remove(FOCUS_CLASS)
      }
    })
  }

  expandChart(dayElement, adding) {
    const dayCanvas = dayElement.querySelector("canvas")
    if (dayCanvas) {
      const chartController = this.application.getControllerForElementAndIdentifier(dayCanvas, "work-chart")
      if (chartController) {
        chartController.updateChart(this.increment, adding)
      }
    }

    const weekCanvas = dayElement.parentNode.parentNode.querySelector(".week__work").querySelector("canvas")
    if (weekCanvas) {
      const weekController = this.application.getControllerForElementAndIdentifier(weekCanvas, "work-chart")
      if (weekController) {
        weekController.updateChart(this.increment, adding)
      }
    }
  }

  updateFollowingDivsPosition(event) {
    if (!this.dragging) return

    if (this.followingDoms) {
      this.followingDoms.forEach((dom) => {
        dom.style.position = "absolute"
        dom.style.maxWidth = "200px"
        dom.style.top = `${event.clientY + 10}px`
        dom.style.left = `${event.clientX + 10}px`
      })
    }
  }

  toggleCarsDeactivation() {
    this.carTargets.forEach((car) => {
      car.classList.toggle(DEACTIVATED_CLASS)
    })
    this.newCarTargets.forEach((newCar) => {
      newCar.classList.toggle(DEACTIVATED_CLASS)
    })
    this.delayedWorkTargets.forEach((delayedWork) => {
      delayedWork.classList.toggle(DEACTIVATED_CLASS)
    })
  }

  dropCar(event, deleteDoms) {
    const dayElement = event.currentTarget
    dayElement.classList.remove(FOCUS_CLASS)

    if (deleteDoms) {
      this.followingDoms.forEach(dom => dom.remove())
      return
    }

    const dayCarsElement = Array.from(dayElement.querySelectorAll(".day__cars"))[0]
    this.followingDoms.forEach((dom) => {
      dayCarsElement.appendChild(dom)
      dom.style.maxWidth = "999px"
      dom.style.position = "inherit"
    })
  }
}
