import { Controller } from "@hotwired/stimulus"

export default class Reloader extends Controller {
  static values = {
    url: String,
    method: String,
    csrf: String,
    target: String,
    ids: String,
  }

  submit() {
    const data = {}
    this.idsValue.split(",").forEach((id) => {
      data[id] = document.getElementById(id).value
    })
    const payload = {
      method: this.methodValue,
      headers: {
        "X-CSRFToken": this.csrfValue,
        "Content-Type": "application/json",
      },
    }
    if (this.methodValue === "POST") {
      payload.body = JSON.stringify(data)
    }
    // eslint-disable-next-line compat/compat
    fetch(this.urlValue, payload).then((response) => {
      document.querySelectorAll(this.targetValue).forEach((element) => {
        element.parentNode.parentNode.dispatchEvent(new Event("reload"))
      })
      window.dispatchEvent(new Event("day-reloader-emit"))
    })
  }
}
