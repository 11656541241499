import { Controller } from "@hotwired/stimulus"
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController, ChartDataLabels)

const extraOptions = { grid: { display: false }, border: { display: false } }
const BLUE_COLOR = "rgb(23, 162, 184)"
const RED_COLOR = "rgb(255, 0, 0)"

const stringify = (x) => {
  const str = x.toString()
  return str.includes(".") ? str.slice(0, str.indexOf(".") + 2) : str
}

export default class WorkChart extends Controller {
  static values = {
    sheetMetal: String,
    painting: String,
    mechanic: String,
    sheetMetalCapacity: String,
    paintingCapacity: String,
    mechanicCapacity: String,
    fontSize: String,
    id: String,
    animated: String,
  }

  connect() {
    this.initVariables()
    this.renderChart()
    this.chart.options.animation = true

    document.addEventListener("theme-change", () => {
      this.chart.destroy()
      this.renderChart()
    })
  }

  initVariables() {
    this._sheetMetalValue = parseFloat(this.sheetMetalValue.replace(",", ".")) || 0
    this._paintingValue = parseFloat(this.paintingValue.replace(",", ".")) || 0
    this._mechanicValue = parseFloat(this.mechanicValue.replace(",", ".")) || 0

    this._sheetMetalCapacity = parseFloat(this.sheetMetalCapacityValue.replace(",", "."))
    this._paintingCapacity = parseFloat(this.paintingCapacityValue.replace(",", "."))
    this._mechanicCapacity = parseFloat(this.mechanicCapacityValue.replace(",", "."))

    this.animated = this.animatedValue !== "false"
  }

  renderChart() {
    const ctx = this.element.getContext("2d")

    const sheetMetalExcess = Math.max(0, this._sheetMetalValue - this._sheetMetalCapacity)
    const paintingExcess = Math.max(0, this._paintingValue - this._paintingCapacity)
    const mechanicExcess = Math.max(0, this._mechanicValue - this._mechanicCapacity)

    const maxCapacity = Math.max(this._sheetMetalCapacity, this._paintingCapacity, this._mechanicCapacity) || 1
    const maxValues = Math.max(this._sheetMetalValue, this._paintingValue, this._mechanicValue)
    const maxValue = Math.max(maxCapacity, maxValues)

    // eslint-disable-next-line no-new
    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Tôlerie", "Peinture", "Mécanique"],
        datasets: [
          {
            label: "Heures",
            data: [this._sheetMetalValue - sheetMetalExcess, this._paintingValue - paintingExcess, this._mechanicValue - mechanicExcess],
            backgroundColor: BLUE_COLOR,
            borderWidth: 1,
            borderRadius: { topLeft: 2, topRight: 0, bottomLeft: 2, bottomRight: 0 },
            borderColor: BLUE_COLOR,
            order: 1,
          },
          {
            label: "Dépassement",
            data: [this._sheetMetalValue, this._paintingValue, this._mechanicValue],
            backgroundColor: RED_COLOR,
            borderWidth: 1,
            borderColor: RED_COLOR,
            order: 2,
            borderRadius: 2,
          },
          {
            label: "Capacité",
            data: [this._sheetMetalCapacity, this._paintingCapacity, this._mechanicCapacity],
            backgroundColor: "transparent",
            borderColor: BLUE_COLOR,
            borderWidth: 1,
            borderRadius: 2,
            borderSkipped: false,
            order: 3,
            minBarLength: 50,
          },
        ],
      },
      options: this.buildOptions(maxValue),
      plugins: [ChartDataLabels],
    })
  }

  updateChart(increment, adding) {
    if (!adding) {
      this._sheetMetalValue -= parseFloat(increment.sheetMetalValue)
      this._paintingValue -= parseFloat(increment.paintingValue)
      this._mechanicValue -= parseFloat(increment.mechanicValue)
    } else {
      this._sheetMetalValue += parseFloat(increment.sheetMetalValue)
      this._paintingValue += parseFloat(increment.paintingValue)
      this._mechanicValue += parseFloat(increment.mechanicValue)
    }

    const sheetMetalExcess = Math.max(0, this._sheetMetalValue - this._sheetMetalCapacity)
    const paintingExcess = Math.max(0, this._paintingValue - this._paintingCapacity)
    const mechanicExcess = Math.max(0, this._mechanicValue - this._mechanicCapacity)

    const maxCapacity = Math.max(this._sheetMetalCapacity, this._paintingCapacity, this._mechanicCapacity)
    const maxValues = Math.max(this._sheetMetalValue, this._paintingValue, this._mechanicValue)
    const newMaxValue = Math.max(maxCapacity, maxValues)

    this.chart.data.datasets[0].data = [
      this._sheetMetalValue - sheetMetalExcess,
      this._paintingValue - paintingExcess,
      this._mechanicValue - mechanicExcess,
    ]
    this.chart.data.datasets[1].data = [
      this._sheetMetalValue,
      this._paintingValue,
      this._mechanicValue,
    ]

    this.chart.options.scales.x.max = newMaxValue
    this.chart.options.scales.y.max = newMaxValue

    this.chart.update()
  }

  buildOptions(maxValue) {
    return {
      responsive: true,
      animation: this.animated,
      ticks: { font: { size: this.buildFontSize() } },
      plugins: {
        tooltip: {
          enabled: true,
          intersect: false,
          mode: "point",
          position: "nearest",
          callbacks: {
            title: function(tooltipItems) {
              return tooltipItems[0].label
            },
            label: function(context) {
              const label = context.dataset.label
              if (label === "Capacité") return `${label}: ${stringify(context.raw)}`
              const hours = [this._sheetMetalValue, this._paintingValue, this._mechanicValue][context.dataIndex]
              if (label === "Heures") return `${label}: ${stringify(hours)}`
              const excess = [
                Math.max(0, this._sheetMetalValue - this._sheetMetalCapacity),
                Math.max(0, this._paintingValue - this._paintingCapacity),
                Math.max(0, this._mechanicValue - this._mechanicCapacity),
              ][context.dataIndex]
              return `${label}: ${stringify(excess)}`
            }.bind(this),
          },
        },
        legend: { display: false },
        datalabels: {
          display: true,
          color: this.buildDataLabelsColor(),
          anchor: "center",
          align: "center",
          formatter: function(_, context) {
            const index = context.dataIndex
            const label = context.dataset.label
            const value = [this._sheetMetalValue, this._paintingValue, this._mechanicValue][index]
            const capacity = [this._sheetMetalCapacity, this._paintingCapacity, this._mechanicCapacity][index]
            const percentage = (capacity === 0) ? 0 : stringify((value / capacity) * 100)
            if (label === "Dépassement") {
              if (value > capacity) return `${percentage}%`
              return null
            }
            if (label === "Heures") return null
            if (label === "Capacité") {
              if (value <= capacity) return `${percentage}%`
              return null
            }
          }.bind(this),
          font: {
            weight: this.buildDataLabelsWeight(),
            size: this.buildFontSize(),
          },
        },
      },
      indexAxis: "y",
      maintainAspectRatio: false,
      scales: {
        y: { stacked: true, beginAtZero: true, ticks: { color: this.buildLabelsColor() }, ...extraOptions },
        x: { display: false, max: maxValue, ...extraOptions },
      },
    }
  }

  buildFontSize() {
    if (this.fontSizeValue === "small") return 12

    const breakpointS = getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-s")
    const breakpointMobile = getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-mobile")

    if (window.matchMedia(`(max-width: ${breakpointS})`).matches) return 14
    if (window.matchMedia(`(max-width: ${breakpointMobile})`).matches) return 12
    return 16
  }

  buildLabelsColor() {
    const colorTextSecondary = getComputedStyle(document.documentElement).getPropertyValue("--color-text-secondary")
    const colorTextPrimary = getComputedStyle(document.documentElement).getPropertyValue("--color-text-primary")

    if (this.fontSizeValue === "small") return colorTextSecondary
    return colorTextPrimary
  }

  buildDataLabelsColor() {
    return getComputedStyle(document.documentElement).getPropertyValue("--color-text-primary")
  }

  buildDataLabelsWeight() {
    return this.fontSizeValue === "small" ? "normal" : "bold"
  }
}
