import { Controller } from "@hotwired/stimulus"
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from "chart.js"

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController)

export default class UsersStats extends Controller {
  static values = { data: String, colors: String }

  connect() {
    const ctx = this.element.getContext("2d")
    const data = JSON.parse(this.dataValue)
    const colors = JSON.parse(this.colorsValue)
    const labels = Object.keys(data)
    const datasets = []

    // Extract user-specific data for each date
    labels.forEach((date, index) => {
      Object.keys(data[date]).forEach(user => {
        let dataset = datasets.find(ds => ds.label === user)
        if (!dataset) {
          dataset = {
            label: user,
            data: new Array(labels.length).fill(0),
            backgroundColor: colors[user],
          }
          datasets.push(dataset)
        }
        dataset.data[index] = data[date][user]
      })
    })

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: "bar",
      data: {
        labels,
        datasets,
      },
      options: { plugins: { datalabels: { display: false } }, scales: { x: { stacked: true }, y: { stacked: true } } },
    })
  }
}
