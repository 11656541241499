import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

const HIDDEN_CLASS = "-hidden"

export default class ReportCar extends Controller {
  static targets = ["stopReporting"]
  static outlets = ["dashboard"]
  static values = { csrf: String }

  report(event) {
    this.stopReportingTarget.classList.toggle(HIDDEN_CLASS)
    this.dashboardOutlet.toggleDragging()
    this.dashboardOutlet.markDueDateExceeded(event.currentTarget.dataset.reportCarDueDateValue)
    this.dashboardOutlet.increment = this.initWorkValues(event)
    this.dashboardOutlet.hideCarOptions()
    this.dashboardOutlet.followingDoms = [event.currentTarget.parentNode.previousElementSibling, event.currentTarget.parentNode]
    this.dashboardOutlet.followingDoms.forEach((dom) => {
      dom.style.maxWidth = "100px"
    })
    this.dashboardOutlet.updateFollowingDivsPosition(event)
    this.reporting = true
    this.dayElement = event.currentTarget.parentNode.parentNode.parentNode

    event.preventDefault()
    event.stopPropagation()
  }

  insert(event) {
    if (!this.reporting) return

    this.dashboardOutlet.insert(event, false)
    this.reporting = false
    if (!this.dashboardOutlet.dragging) {
      this.stopReportingTarget.classList.add(HIDDEN_CLASS)
      this.updateStateDate(event).then((dayElement) => {
        dayElement.dispatchEvent(new Event("reload"))
      })
    }
  }

  toggleStopReporting() {
    this.stopReportingTarget.classList.toggle(HIDDEN_CLASS)
    this.dashboardOutlet.toggleDragging()
    this.reporting = false

    if (!this.dashboardOutlet.dragging) this.dayElement.dispatchEvent(new Event("reload"))
  }

  // ##### Internal functions #####

  initWorkValues(event) {
    const sheetMetalValue = parseFloat(event.currentTarget.dataset.reportCarSheetMetalValue.replace(",", ".")) || 0
    const paintingValue = parseFloat(event.currentTarget.dataset.reportCarPaintingValue.replace(",", ".")) || 0
    const mechanicValue = parseFloat(event.currentTarget.dataset.reportCarMechanicValue.replace(",", ".")) || 0
    this.carPk = event.currentTarget.dataset.reportCarPkValue
    return { sheetMetalValue, paintingValue, mechanicValue }
  }

  async updateStateDate(event) {
    const dayElement = event.currentTarget
    const stateDate = dayElement.dataset.dashboardDateValue

    // eslint-disable-next-line compat/compat
    const response = await fetch(`/api/cars-in-charge/${this.carPk}/update-state-date/`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRFToken": this.csrfValue },
      body: JSON.stringify({ state_date: stateDate }),
    })
    const data = await response.json()
    if (response.ok && data?.message) {
      new Notyf({ duration: 15000, ripple: false }).open({
        type: "custom",
        message: data.message,
        background: "orange",
        dismissible: true,
      })
    }

    return dayElement
  }
}
