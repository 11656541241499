import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

export default class Note extends Controller {
  static values = { csrf: String, removeUrl: String }

  remove(event) {
    // eslint-disable-next-line compat/compat
    fetch(this.removeUrlValue, {
      method: "POST",
      headers: {
        "X-CSRFToken": this.csrfValue,
      },
    }).then((response) => {
      if (!response.ok) {
        new Notyf({ duration: 4000, ripple: false }).error("Une erreur est survenue lors de la suppression de la note")
      } else {
        new Notyf({ duration: 4000, ripple: false }).success("La note a été supprimée avec succès")
        this.element.remove()
      }
    })

    event.preventDefault()
    event.stopPropagation()
  }
}
