import { Controller } from "@hotwired/stimulus"

export default class ContractForm extends Controller {
  static targets = ["submit", "endDate", "type"]

  manageButtonState() {
    const contractType = this.typeTarget.value
    const endDate = this.endDateTarget.value

    if (contractType === "cdi") {
      this.submitTarget.disabled = false
      return
    }

    if (endDate) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
