import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class SiretVerification extends Controller {
  static targets = ["siret", "submit", "errorMessage"]

  checkSiret() {
    if (this.siretTarget.value.length !== 14) return

    const url = this.element.dataset.siretVerificationUrlValue
    // eslint-disable-next-line compat/compat
    fetch(`${url}?siret=${this.siretTarget.value}`)
      .then(response => response.json())
      .then(data => {
        if (data.unicity) this.markAsValid()
        else this.markAsInvalid()
      })
  }

  // #### Internal methods ####

  markAsInvalid() {
    this.submitTarget.disabled = true
    this.errorMessageTarget.classList.remove(HIDDEN_CLASS)
  }

  markAsValid() {
    this.submitTarget.disabled = false
    this.errorMessageTarget.classList.add(HIDDEN_CLASS)
  }
}
