import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

const HIDDEN_CLASS = "-hidden"

export default class StateChoice extends Controller {
  static targets = ["states", "mechanic", "painting", "sheetMetal"]
  static values = { url: String, csrf: String, carPk: String }

  open() {
    this.statesTarget.classList.remove(HIDDEN_CLASS)
  }

  close() {
    this.statesTarget.classList.add(HIDDEN_CLASS)
  }

  blur(event) {
    if (this.element === event.target || this.element.contains(event.target)) return
    this.close()
  }

  repairingStateChosen() {
    const mechanicState = this.hasMechanicTarget ? this.mechanicTarget.value : null
    const paintingState = this.hasPaintingTarget ? this.paintingTarget.value : null
    const sheetMetalState = this.hasSheetMetalTarget ? this.sheetMetalTarget.value : null

    this.postState("repairing", {
      mechanic_state: mechanicState,
      painting_state: paintingState,
      sheet_metal_state: sheetMetalState,
    })
  }

  change(event) {
    const dom = event.currentTarget
    const state = dom.dataset.stateChoiceValueData

    if (state === "repairing") {
      document.getElementById(`repairing-states-${this.carPkValue}`).classList.remove(HIDDEN_CLASS)
      event.preventDefault()
      event.stopPropagation()
      return
    }

    this.postState(state)
  }

  // ##### Internal functions #####

  postState(state, repairingStates = null) {
    const body = repairingStates ? { state, ...repairingStates } : { state }
    // eslint-disable-next-line compat/compat
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      this.element.dispatchEvent(new CustomEvent(`reload-day-car-${this.carPkValue}`))
      if (!response.ok) {
        new Notyf({ duration: 2000, ripple: false }).error("Une erreur est survenue lors de la modification de l'état du véhicule")
      } else {
        new Notyf({ duration: 2000, ripple: false }).success("L'état du véhicule a été modifié avec succès")
      }
    })

    this.close()
  }
}
