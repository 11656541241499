import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class DelayWorks extends Controller {
  static targets = ["sheetMetal", "painting", "mechanic", "reason", "submit", "stopDelayingWorks"]
  static outlets = ["dashboard"]
  static values = { csrf: String, postUrl: String }

  connect() {
    this.delayWorkModal = document.getElementById("delay-work")
  }

  openDelayModal(event) {
    this.initTotalHours(event)
    this.setHoursTargetValues()
    this.delayWorkModal.classList.remove(HIDDEN_CLASS)
    this.carPk = event.currentTarget.dataset.delayWorksCarPkValue
    this.workPk = event.currentTarget.dataset.delayWorksLastDelayValue
    this.isDelayedAgain = event.currentTarget.dataset.delayWorksDelayAgainValue
    this.dueDate = event.currentTarget.dataset.delayWorksCarDueDateValue
    this.dashboardOutlet.hideCarOptions()
    this.dayElement = event.currentTarget.parentNode.parentNode.parentNode

    event.preventDefault()
    event.stopPropagation()
  }

  async delay(event) {
    this.stopDelayingWorksTarget.classList.remove(HIDDEN_CLASS)
    this.delayWorkModal.classList.add(HIDDEN_CLASS)
    this.dashboardOutlet.toggleDragging()
    this.dashboardOutlet.markDueDateExceeded(this.dueDate)
    this.sheetMetalValue = parseFloat(this.sheetMetalTarget.value.replace(",", ".")) || 0
    this.paintingValue = parseFloat(this.paintingTarget.value.replace(",", ".")) || 0
    this.mechanicValue = parseFloat(this.mechanicTarget.value.replace(",", ".")) || 0
    this.reasonValue = this.reasonTarget.value

    this.dashboardOutlet.increment = {
      sheetMetalValue: Math.min(this.totalSheetMetal, this.sheetMetalValue),
      paintingValue: Math.min(this.totalPainting, this.paintingValue),
      mechanicValue: Math.min(this.totalMechanic, this.mechanicValue),
    }
    this.dashboardOutlet.expandChart(this.dayElement, false)

    this.dashboardOutlet.increment = {
      sheetMetalValue: this.sheetMetalValue,
      paintingValue: this.paintingValue,
      mechanicValue: this.mechanicValue,
    }

    this.dashboardOutlet.followingDoms = await this.createDelayedWorkElements()
    this.dashboardOutlet.updateFollowingDivsPosition(event)
    this.dashboardOutlet.hideCarOptions()
    this.delayingWorks = true
  }

  toggleStopDelayingWorks() {
    this.stopDelayingWorksTarget.classList.toggle(HIDDEN_CLASS)
    this.dashboardOutlet.toggleDragging()
    this.delayingWorks = false

    if (!this.dashboardOutlet.dragging) {
      this.dashboardOutlet.followingDoms.forEach((dom) => {
        dom.remove()
      })
      this.dayElement.dispatchEvent(new Event("reload"))
    }
  }

  insert(event) {
    if (this.reporting) {
      const dayElement = event.currentTarget
      this.updateDelayWork(dayElement).then(() => {
        dayElement.dispatchEvent(new Event("reload"))
        this.dayElement.dispatchEvent(new Event("reload"))
      })
      this.reporting = false
      this.stopDelayingWorksTarget.classList.add(HIDDEN_CLASS)
      this.dashboardOutlet.insert(event)
      return
    }
    if (!this.delayingWorks) return

    this.dashboardOutlet.insert(event)
    this.delayingWorks = false
    if (!this.dashboardOutlet.dragging) {
      this.stopDelayingWorksTarget.classList.add(HIDDEN_CLASS)
      this.addDelayedWorks(event)
    }
  }

  report(event) {
    this.stopDelayingWorksTarget.classList.toggle(HIDDEN_CLASS)
    this.dashboardOutlet.toggleDragging()
    this.dashboardOutlet.markDueDateExceeded(event.currentTarget.dataset.delayWorksCarDueDateValue)
    this.dashboardOutlet.increment = { sheetMetalValue: parseFloat(event.currentTarget.dataset.delayWorksSheetMetalValue.replace(",", ".")) || 0, paintingValue: parseFloat(event.currentTarget.dataset.delayWorksPaintingValue.replace(",", ".")) || 0, mechanicValue: parseFloat(event.currentTarget.dataset.delayWorksMechanicValue.replace(",", ".")) || 0 }
    this.dashboardOutlet.hideCarOptions()
    this.dashboardOutlet.followingDoms = [event.currentTarget.parentNode.previousElementSibling, event.currentTarget.parentNode]
    this.dashboardOutlet.followingDoms.forEach((dom) => {
      dom.style.maxWidth = "100px"
    })
    this.dashboardOutlet.updateFollowingDivsPosition(event)
    this.reporting = true
    this.dayElement = event.currentTarget.parentNode.parentNode.parentNode
    this.workPk = event.currentTarget.dataset.delayWorksPkValue
    this.carPk = event.currentTarget.dataset.delayWorksCarPkValue

    event.preventDefault()
    event.stopPropagation()
  }

  remove(event) {
    const eventTarget = event.currentTarget
    const dayElement = eventTarget.parentNode.parentNode.parentNode
    this.deleteWork(eventTarget.dataset.delayWorksCarPkValue, eventTarget.dataset.delayWorksPkValue).then(() => {
      dayElement.dispatchEvent(new Event("reload"))

      if (eventTarget.dataset.delayWorksCarStartWorkValue) {
        document.getElementById(`day-${eventTarget.dataset.delayWorksCarStartWorkValue}`).dispatchEvent(new Event("reload"))
      }
    })
  }

  // ##### Internal functions #####

  initTotalHours(event) {
    this.totalSheetMetal = parseFloat(event.currentTarget.dataset.delayWorksCarSheetMetalValue.replace(",", ".")) || 0
    this.totalPainting = parseFloat(event.currentTarget.dataset.delayWorksCarPaintingValue.replace(",", ".")) || 0
    this.totalMechanic = parseFloat(event.currentTarget.dataset.delayWorksCarMechanicValue.replace(",", ".")) || 0
  }

  setHoursTargetValues() {
    this.sheetMetalTarget.value = this.totalSheetMetal
    this.paintingTarget.value = this.totalPainting
    this.mechanicTarget.value = this.totalMechanic
    this.reasonTarget.value = ""
  }

  addDelayedWorks(event) {
    const dayElement = event.currentTarget
    let url = this.postUrlValue
    if (this.isDelayedAgain === "true") {
      url += `?work_pk=${this.workPk}`
    }
    // eslint-disable-next-line compat/compat
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRFToken": this.csrfValue },
      body: JSON.stringify({
        date: dayElement.dataset.dashboardDateValue,
        car_pk: this.carPk,
        sheet_metal_work_hours: this.sheetMetalValue,
        painting_hours: this.paintingValue,
        mechanic_hours: this.mechanicValue,
        reason: this.reasonValue,
      }),
    }).then(() => {
      this.dayElement.dispatchEvent(new Event("reload"))
      dayElement.dispatchEvent(new Event("reload"))
    })
  }

  async createDelayedWorkElements() {
    // eslint-disable-next-line compat/compat
    const response = await fetch(`/api/cars-in-charge/${this.carPk}/delayed-work-template/?sheet_metal_hours=${this.sheetMetalValue || 0}&painting_hours=${this.paintingValue || 0}&mechanic_hours=${this.mechanicValue || 0}&reason=${this.reason || ""}`, { method: "GET" })
    const data = await response.json()
    const dom = new DOMParser().parseFromString(data.html, "text/html").body.childNodes[0]
    document.body.appendChild(dom)

    return [dom]
  }

  async deleteWork(carPk, workPk) {
    // eslint-disable-next-line compat/compat
    await fetch(`/api/cars-in-charge/${carPk}/delete-delayed-works/`, {
      method: "POST",
      headers: { "X-CSRFToken": this.csrfValue, "Content-Type": "application/json" },
      body: JSON.stringify({ work_pk: workPk }),
    })
  }

  async updateDelayWork(dayElement) {
    const workDate = dayElement.dataset.dashboardDateValue
    // eslint-disable-next-line compat/compat
    await fetch(`/api/cars-in-charge/${this.carPk}/update-delayed-works/`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRFToken": this.csrfValue },
      body: JSON.stringify({ date: workDate, work_pk: this.workPk }),
    })
  }
}
